import React, { useEffect, useState } from 'react';
import { Card, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Fade } from '@mui/material';
import { CardContent } from '@mui/material';
import { TextField, Box, Typography, InputAdornment } from '@mui/material';
import { FormControl } from '@mui/material';
import { Button } from '@mui/material';
import { Container } from '@mui/material';
import { CircularProgress } from '@mui/material';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Link } from '@mui/material';
//import { GoogleLogin } from 'react-google-login';
import { NavLink } from 'react-router-dom';
import { BrowserView, MobileView, isBrowser } from 'react-device-detect';
import { useNavigate } from "react-router-dom";
import { FaceOutlined } from '@mui/icons-material';
import { firebaseAuth, getFacebookProvider, getGoogleProvider, signInWithLinkedin } from './AuthSocialNetwork';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import PageLoader from '../commons/PageLoader';
import DialogHelp from './DialogHelp';
import { connect } from 'react-redux';
import { notifyLogin } from '../reduxActions/Actions';
import api from '../services/api';
import i18n from '../commons/i18n';

const Login = (props) => {
    const [loading, setLoading] = useState();
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [errorOpen, setErrorOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('TOKEN')) {
            navigate("/home", { replace: true });
        }
    }, []);

    const handleSubmit = e => {
        e.preventDefault();
        setLoading(true);
        const token = logUser({
            username,
            password
        });
    }

    async function logSocial(userAuth) {
        var email = userAuth.providerData[0].email;
        var provider_id = userAuth.providerData[0].providerId;
        // The signed-in user info.
        let payload = { email: email, uid: userAuth.uid, provider: provider_id };
        api.post('v1/loginsocial', payload)
            .then(response => {
                const data = response.data;
                console.log(data)
                let token = data.msg.TOKEN;
                if (token) {
                    localStorage.setItem('ID', data.msg.ID);
                    localStorage.setItem('LANG', data.msg.LANG);
                    localStorage.setItem('EMAIL', data.msg.EMAIL);
                    localStorage.setItem('TOKEN', data.msg.TOKEN);
                    localStorage.setItem('REFRESH_TOKEN', data.msg.REFRESH_TOKEN);
                    localStorage.setItem('IUP', data.msg.IUP);        // is user proactive
                    localStorage.setItem('INVUNIT', data.msg.INVUNIT);
                    localStorage.setItem('MSGT', data.msg.MSGT);
                    localStorage.setItem('LN', data.msg.LN);
                    localStorage.setItem('VF', data.msg.VF);

                    i18n.changeLanguage(data.msg.LANG)
                    i18n.reloadResources();

                    if (data.msg.QST === 0 && data.msg.TOKEN) {
                        props.login()
                    } else {
                        navigate("/profiling", { replace: true });
                    }
                } else {
                    console.log(response)
                    setLoading(false);
                }
            }).catch(error => {
                console.log('error', error);
                setLoading(false);
            })
    }

    async function logUser(credentials) {
        let payload = { email: credentials.username, password: credentials.password };
        api.post('login', payload)
            .then(response => {
                const data = response.data;
                let token = data.msg.TOKEN;
                if (token) {
                    localStorage.setItem('ID', data.msg.ID);
                    localStorage.setItem('UT', data.msg.UT);
                    localStorage.setItem('LANG', data.msg.LANG);
                    localStorage.setItem('EMAIL', data.msg.EMAIL);
                    localStorage.setItem('TOKEN', data.msg.TOKEN);
                    localStorage.setItem('REFRESH_TOKEN', data.msg.REFRESH_TOKEN);
                    localStorage.setItem('IUP', data.msg.IUP);        // is user proactive
                    localStorage.setItem('INVUNIT', data.msg.INVUNIT);
                    localStorage.setItem('MSGT', data.msg.MSGT);
                    localStorage.setItem('QST', data.msg.QST);
                    localStorage.setItem('SUBSCRIBED', data.msg.SUBSCRIBED);
                    localStorage.setItem('LN', data.msg.LN);
                    localStorage.setItem('VF', data.msg.VF);

                    i18n.changeLanguage(data.msg.LANG)
                    i18n.reloadResources();

                    if (data.msg.QST === 0 && data.msg.TOKEN) {
                        props.dispatch(notifyLogin())
                        props.login()
                        validateUser()
                    } else {
                        navigate("/profiling", { replace: true });
                    }

                } else {
                    console.log(data.error)
                    setLoading(false);
                    setErrorOpen(true)
                }
            }).catch(error => {
                console.log(error);
                setLoading(false);
            })
    }

    function validateUser() {
        let config = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('TOKEN') } }
        api.get('validation/' + localStorage.getItem('ID'))
            .then(response => {
                const data = response.data;
                if (data === "0") navigate("/identify", { replace: true });
            }).catch(error => {
                console.log(error);
            })
    }

    function googleLogin(t) {
        firebaseAuth.signInWithPopup(getGoogleProvider).then(function (result) {
            logSocial(result.user)
        })
    }
    function facebookLogin(t) {
        firebaseAuth.signInWithPopup(getFacebookProvider).then(function (result) {
            logSocial(result.user)
        })
    }
    function linkedinLogin(t) {
        console.log(getFacebookProvider)
    }

    function close() {
        setErrorOpen(false)
    }

    return (
        <div>
            <DialogHelp id={1} isHelpDialogOpen={errorOpen} closeHelpDialog={close} helpDialogTitle={t("acc_expTitle")} helpDialogContent={t("Loginerrcred")} />
            {isBrowser ?
                <BrowserView>
                    <Fade in={true} timeout={450}>
                        <Container maxWidth="sm" sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
                            <Card variant="outlined" sx={{ width: '100%' }}>
                                {loading ? <PageLoader /> : ''}
                                <CardContent sx={{ padding: '32px' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', py: 3 }}>
                                            <img style={{ margin: '16px' }} height={80} alt="Logo" src="/LogoColVert.svg" />
                                        </Box>
                                        <Box sx={{ display: 'flex', pb: 4, width: '100%', alignItems: 'end' }}>
                                            <Box sx={{ flex: '100%' }}>
                                                <Typography variant='h4' className='big'><Trans>LoginButton</Trans></Typography>
                                                <Typography variant='caption' color={'secondary'}><Trans>LoginSubTitle</Trans></Typography>
                                            </Box>
                                        </Box>
                                        <FormControl variant="outlined" sx={{ pb: 2 }}>
                                            <TextField sx={{ width: '100%' }}
                                                label={<Typography variant='overline'>email</Typography>}
                                                variant="outlined"
                                                onChange={e => setUserName(e.target.value)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <EmailOutlinedIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </FormControl>
                                        <FormControl variant="outlined" sx={{ pb: 2 }}>
                                            <TextField sx={{ width: '100%' }}
                                                type="password"
                                                label={<Typography variant='overline'>password</Typography>}
                                                //helperText="Insert your password"
                                                variant="outlined"
                                                onChange={e => setPassword(e.target.value)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <LockOutlinedIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <Box sx={{ mt: 1 }}><Typography sx={{ pt: 1 }} variant='body2'>
                                                <NavLink to="/reset"><Trans>LoginForgotLink</Trans></NavLink>
                                            </Typography></Box>
                                        </FormControl>
                                        <FormControl variant="outlined" sx={{}}>
                                            <Button disableElevation sx={{ width: '100%' }} size="large" onClick={handleSubmit} variant="contained" color="primary"><Trans>LoginButton</Trans></Button>
                                            <Divider sx={{my: 5, mx: 5}}/>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', alignSelf: 'center', width: 300 }}>
                                                <Button
                                                    color='inherit'
                                                    startIcon={<img width={20} src="Google.svg" />}
                                                    variant='outlined'
                                                    sx={{ mb: 1, display: 'flex', justifyContent: 'flex-start',  }}
                                                    onClick={googleLogin}><Trans>LoginWithGoogle</Trans>
                                                </Button>
                                                {/* <Button
                                                color='inherit'
                                                startIcon={<img width={20} src="Facebook.png" />}
                                                variant='outlined'
                                                sx={{ mb: 1, display: 'flex', justifyContent: 'flex-start', width: '100%' }}
                                                onClick={facebookLogin}><Trans>LoginWithFacebook</Trans>
                                            </Button> */}
                                                {/* <Button
                                                    color='inherit'
                                                    startIcon={<img width={20} src="Linkedin.png" />}
                                                    variant='outlined'
                                                    sx={{ mb: 1, display: 'flex', justifyContent: 'flex-start', width: '100%' }}
                                                    onClick={signInWithLinkedin}><Trans>LoginWithLinkedin</Trans>
                                                </Button> */}
                                            </Box>
                                            <Box sx={{ mt: 3 }}><Typography component={NavLink} to="/signup" button variant='body2'><Trans>LoginSignup</Trans></Typography></Box>
                                        </FormControl>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Container>
                    </Fade>
                </BrowserView>
                :
                <MobileView>
                    <Fade in={true} timeout={450}>
                        <Container maxWidth="sm" sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
                            {loading ? <PageLoader /> : ''}
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
                                    <img height={80} alt="Logo" src="/LogoColVert.svg" />
                                </Box>
                                <Box sx={{ display: 'flex', pb: 4, width: '100%', alignItems: 'end' }}>
                                    <Box sx={{ flex: '100%' }}>
                                        <Typography variant='h4' className='big'><Trans>LoginButton</Trans></Typography>
                                        <Typography variant='caption' color={'secondary'}><Trans>LoginSubTitle</Trans></Typography>
                                    </Box>
                                </Box>
                                <FormControl variant="outlined" sx={{ pb: 2 }}>
                                    <TextField sx={{ width: '100%' }}
                                        label={<Typography variant='overline'>email</Typography>}
                                        variant="outlined"
                                        onChange={e => setUserName(e.target.value)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <EmailOutlinedIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                                <FormControl variant="outlined" sx={{ pb: 2 }}>
                                    <TextField sx={{ width: '100%' }}
                                        type="password"
                                        label={<Typography variant='overline'>password</Typography>}
                                        //helperText="Insert your password"
                                        variant="outlined"
                                        onChange={e => setPassword(e.target.value)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <LockOutlinedIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <Box sx={{ mt: 1 }}><Typography sx={{ pt: 1 }} variant='body2'>
                                        <NavLink to="/reset"><Trans>LoginForgotLink</Trans></NavLink>
                                    </Typography></Box>
                                </FormControl>
                                <FormControl variant="outlined" sx={{}}>
                                    <Button disableElevation sx={{ width: '100%' }} size="large" onClick={handleSubmit} variant="contained" color="primary"><Trans>LoginButton</Trans></Button>
                                    <Divider sx={{my: 5, mx: 5}}/>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', alignSelf: 'center', width: 300 }}>
                                        <Button
                                            color='inherit'
                                            startIcon={<img width={20} src="Google.svg" />}
                                            variant='outlined'
                                            sx={{ mb: 1, display: 'flex', justifyContent: 'flex-start' }}
                                            onClick={googleLogin}><Trans>LoginWithGoogle</Trans>
                                        </Button>
                                        {/* <Button
                                        color='inherit'
                                        startIcon={<img width={20} src="Facebook.png" />}
                                        variant='outlined'
                                        sx={{ mb: 1, display: 'flex', justifyContent: 'flex-start', width: '100%' }}
                                        onClick={facebookLogin}><Trans>LoginWithFacebook</Trans>
                                    </Button> */}
                                        {/* <Button
                                            color='inherit'
                                            startIcon={<img width={20} src="Linkedin.png" />}
                                            variant='outlined'
                                            sx={{ mb: 1, display: 'flex', justifyContent: 'flex-start', width: '100%' }}
                                            onClick={signInWithLinkedin}><Trans>LoginWithLinkedin</Trans>
                                        </Button> */}
                                    </Box>
                                    <Box sx={{ mt: 3 }}><Typography component={NavLink} to="/signup" button variant='body2'><Trans>LoginSignup</Trans></Typography></Box>
                                </FormControl>
                            </Box>
                        </Container>
                    </Fade>
                </MobileView>
            }
        </div>
    )
}

export default connect()(Login);