import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Box, Button, Dialog, DialogContent, Fade, MobileStepper, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';

class IntroMyPortfolios extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0,
      activeColor: 'transparent', //linear-gradient(to right, #e8d3fb 35%, #c690f4 99%)',
      stepNumber: 2,
      steps: [
        <Fade in={true} timeout={450}>
          <Box sx={{ display: 'flex', textAlign: 'center', flexDirection: 'column' }}>
            <img src="/img/intro/advices.svg" alt='Advices' style={{ maxHeight: 250 }} />
            <Typography sx={{ mt: 5, mb: 1, textTransform: 'uppercase' }} variant='h4' className='big'><Trans>onboarding_mz1_title</Trans></Typography>
            <Box><Typography variant='body2' component={'h5'}>
              <Trans components={{ highlighter: <Typography variant='inherit' className='big' color='primary' component={'span'}></Typography> }}>onboarding_mz1_text</Trans>
            </Typography></Box>
          </Box>
        </Fade>,
        <Fade in={true} timeout={450}>
          <Box sx={{ display: 'flex', textAlign: 'center', flexDirection: 'column' }}>
            <img src="/img/intro/intro-mypos.svg" alt='Positions' height={250} />
            <Typography sx={{ mt: 5, mb: 1, textTransform: 'uppercase' }} variant='h4' className='big'><Trans>onboarding_mz2_title</Trans></Typography>
            <Typography variant='body2' component={'h5'}>
              <Trans components={{ highlighter: <Typography variant='inherit' className='big' color='primary' component={'span'}></Typography> }}>onboarding_mz2_text</Trans>
            </Typography>
          </Box>
        </Fade>,
      ],
    }
  }

  handleNext() {
    this.setState((prevState, props) => ({
      activeStep: prevState.activeStep + 1,
      activeStatus: false
    }));
  }

  handleBack() {
    this.setState((prevState, props) => ({
      activeStep: prevState.activeStep - 1
    }));
  }

  handleEnd() {
    this.props.introClose()
    localStorage.setItem("ONB_MYPORTFOLIOS", 1)
  }

  render() {
    const { activeStep, steps, stepNumber, activeColor } = this.state;
    const props = this.props;
    return (
      <div>
        <Dialog open={props.open} onClose={props.introClose} fullWidth sx={{ borderColor: 'transparent!important' }}>
          <DialogContent sx={{ background: activeColor }}>
            <Box sx={{ mb: 7 }}>
              {steps[activeStep]}
            </Box>
            <MobileStepper
              variant="dots"
              steps={stepNumber}
              position="static"
              activeStep={activeStep}
              sx={{ flexGrow: 1, background: 'transparent', borderColor: 'transparent!important' }}
              nextButton={
                <>
                  {activeStep === stepNumber - 1 ?
                    <Button disableElevation variant='contained' size="small" onClick={() => this.handleEnd()} endIcon={<KeyboardArrowRight />}>
                      {t('onboarding_start')}
                    </Button>
                    :
                    <Button disableElevation variant='outlined' size="small" onClick={() => this.handleNext()} endIcon={<KeyboardArrowRight />}>
                      {t('onboarding_next')}
                    </Button>}
                </>
              }
              backButton={
                <Button disableElevation size="small" onClick={() => this.handleBack()} disabled={activeStep === 0} startIcon={<KeyboardArrowLeft />}>
                  {t('onboarding_back')}
                </Button>
              }
            />
          </DialogContent>
        </Dialog>
      </div>
    )
  }
}

export default IntroMyPortfolios;